<template>
  <div>
    <main
      v-if="!initializing"
      class="w-screen min-h-screen bg-eee"
    >
      <router-view :key="$route.path" />
    </main>
    <div
      v-else
      class="fixed flex items-center justify-center w-screen h-screen"
    >
      <div class="loading-wrapper">
        <div class="sk-wave">
          <div class="sk-wave-rect" />
          <div class="sk-wave-rect" />
          <div class="sk-wave-rect" />
          <div class="sk-wave-rect" />
          <div class="sk-wave-rect" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex'
export default {
  name: 'Main',
  data() {
    return {
      initializing: true
    }
  },
  async mounted() {
    // await this.initialize()
    this.initializing = false
  },
  methods: {
    ...mapActions({
      initialize: 'Common/Credential/initialize'
    })
  }
}
</script>
